import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Warenbewegungen from '../../../components/ProductDetails/netstorsys/warenbewegungen'
import Seo from "../../../components/App/Seo"

const WarenbewegungenPage = () => {
  return (
    <Layout>
      <Seo title={"Lagerverwaltungssoftware mit intelligente Warenbewegungen"}
           description={"Durch das Konzept von Warenbewegungen mittels " +
           "Transportaufträgen erhöht NETSTORSYS die Effizienz im Lager und " +
           "steigert die Transparenz von Warenbewegungen."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Warenbewegungen />
      <Footer />
    </Layout>
  );
}

export default WarenbewegungenPage
