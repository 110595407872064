import React from "react"
import warenbewegungenHeader from "../../../assets/images/products/netstorsys/transportauftraege_header.svg"
import wmTransportauftraege from "../../../assets/images/products/netstorsys/wm-transportauftraege.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "../../App/FeatureSection"

const Warenbewegungen = () => {
   return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={warenbewegungenHeader} alt="about" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETSTORSYS</span>
                <h1 className={"pt-3 text-break"}>Lagerverwaltungssoftware mit intelligenten Warenbewegungen</h1>
                <p>
                  Die Notwendigkeit Ware von A nach B zu transportieren wird in einem Transportauftrag festgehalten. Ein
                  klassischer Transportauftrag wäre z.B. die Entnahme (Picking) eines Artikels, um ihn beispielsweise
                  später an den Versand zu übergeben. Transportaufträge werden vom System generiert und bilden somit die
                  direkte physische Lagerbewegung bei der Kommissionierung von Aufträgen ab.
                </p>
                <p>
                  Weitere Bewegungen der Ware, wie bspw. das Umlagerung, werden außerdem im System genau erfasst und
                  zusammen mit den Transportaufträgen eine vollständige Übersicht über alle Aktionen im Lager. Darüber
                  lassen sich auch individuelle Prozesse im Lager direkt mit der Lagerverwaltungssoftware abbilden und umsetzen.
                </p>
                <p>
                  Weitere Bewegungen der Ware, wie bspw. das Umlagerung, werden außerdem im System genau erfasst und
                  zusammen mit den Transportaufträgen eine vollständige Übersicht über alle Aktionen im Lager. Darüber
                  lassen sich auch individuelle Prozesse im Lager direkt abbilden und umsetzen.
                </p>

                <WichtigeMerkmale items={[
                  "Warenprüfung",
                  "Prozessnachverfolgung",
                  "Bestandssicherheit",
                  "geführte Prozesse",
                  "Erweiterbarkeit",
                ]} image={<img src={wmTransportauftraege}
                               alt="Warenbewegungen Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <p>
                  Durch das Festhalten jeder Lagerbewegung, mit der Möglichkeit die Details eines Transportauftrags
                  einzusehen, werden Abläufe im Lager transparent. Sie sind außerdem die Basis von weitergehenden
                  Auswertungen wie z.B. ABC Analysen oder Pick-Optimierungsstrategien.
                </p>

                <h3>Relevantes aus dem Lexikon</h3>
                <LexikonBadgesRandomSection product={"Netstorsys"}/>

              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>

      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_torders_framed.png"
                        alt="Warenbewegungen Screenshot"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Warenbewegungen in NETSTORSYS"}
                      textBlock1={<span>Transportaufträge machen das Lager transparent. Die Anzahl der Transkationen können statistisch
                ausgewertet werden, sodass auch Kapazitätsplanungen durchgeführt werden können. Durch die direkte
                Koppelung an Bestellungen lassen sich beispielsweise schnell und einfach Rückschlüsse zur benötigten
                Lagermenge viel gefragter Artikel ziehen.</span>}
                      textBlock2={<span>Die die Unterteilung von Warenbewegungen in unterschiedliche Typen in einer Batch, ist auch leicht zu
                erkennen welcher Bereich aktuell besonders beansprucht ist und wo noch Kapazitäten vorhanden sind.</span>}
                      textBlock3={<span>So ist es zum Beispiel leicht möglich zu erkennen, das im Picking die Resourcen knapp werden, während
                bei der Einlagerung gerade das Volumen abnimmt. Da bei der Lagerverwaltungssoftware jeder Mitarbeiter
                grundsätzliche die gleiche Hardware hat, ist es ohne weiteres möglich Mitarbeiter in andere Bereiche
                zu migrieren.</span>}
      />

      <FeatureSection backgroundColorClass={"bg-23173a"}
                      imagePosition={"right"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_batch_framed.png"
                        alt="Warenbewegungen Screenshot"
                        placeholder="blurred"
                        width={700}
                      />}
                      header={"Batch Management"}
                      textBlock1={<span>Das Batch System ist übersichtlich gestaltet und lässt zu, dass auf einen Blick verschiedene Prozesse
                erfasst und kontrolliert werden können. Im Detail lassen sich zusätzlich für jeden Typ der Warenbewegung
                wichtige Informationen anzeigen. Darüber können simple Auswertungen direkt im System erfolgen und
                fehlerhafte Operationen erkennen. Beispielsweise ob ein Artikel an einen falschen Lagerplatz bewegt
                wurde oder eine viel zu hohe Menge eines Artikels für einen Auftrag kommissioniert worden ist. Die
                Lagerverwaltungssoftware NETSTORSYS verschafft Ihnen Transparenz in Ihrem Lager.</span>}
                      textBlock2={<span>Die die Unterteilung von Warenbewegungen in unterschiedliche Typen in einer Batch, ist auch leicht zu
                erkennen welcher Bereich aktuell besonders beansprucht ist und wo noch Kapazitäten vorhanden sind.</span>}
                      textBlock3={<span>So ist es zum Beispiel leicht möglich zu erkennen, das im Picking die Resourcen knapp werden, während
                bei der Einlagerung gerade das Volumen abnimmt. Da bei der Lagerverwaltungssoftware jeder Mitarbeiter
                grundsätzliche die gleiche Hardware hat, ist es ohne weiteres möglich Mitarbeiter in andere Bereiche
                zu migrieren.</span>}
      />
    </>
  )
}

export default Warenbewegungen
